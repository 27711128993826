<template>
  <div class="associationKnowledge">
    <el-table
        :data="knowledgeList"
        style="width: 100%"
        :header-cell-style="{backgroundColor:'#F6F8FD',height:'50px',color:'#000000',fontWeight:400}"
        :row-style="{cursor:'pointer',color:'#000000',height:'50px'}"
        height="calc(100vh - 120px)"
    >
      <el-table-column
          prop="name"
          :label="$t('knowledgeManagement.components.associationKnowledge.el-table-column.labelName')"
      >
        <template slot-scope="scope">
          <div class="doc-name">
            <template v-if="scope.row.carrier">
              <img src="../../../assets/images/video.png"
                   v-if="setSrc(scope.row.carrier.type) == 'video'" />
              <img src="../../../assets/images/img.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'image'" />
              <img src="../../../assets/images/txt.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'txt'" />
              <img src="../../../assets/images/doc.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'word'" />
              <img src="../../../assets/images/pdf.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'pdf'" />
              <img src="../../../assets/images/ppt.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'ppt'" />
              <img src="../../../assets/images/xls.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'excel'" />
              <img src="../../../assets/images/html.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'html'" />
              <img src="../../../assets/images/mp3.png"
                   v-else-if="setSrc(scope.row.carrier.type) == 'audio'"/>
              <img src="../../../assets/images/other.png" v-else>
            </template>
            <template v-else-if="scope.row.fileSuffix">
              <img src="../../../assets/images/video.png"
                   v-if="setSrc(scope.row.fileSuffix) == 'video'" />
              <img src="../../../assets/images/img.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'image'" />
              <img src="../../../assets/images/txt.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'txt'" />
              <img src="../../../assets/images/doc.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'word'" />
              <img src="../../../assets/images/pdf.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'pdf'" />
              <img src="../../../assets/images/ppt.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'ppt'" />
              <img src="../../../assets/images/xls.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'excel'" />
              <img src="../../../assets/images/html.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'html'" />
              <img src="../../../assets/images/mp3.png"
                   v-else-if="setSrc(scope.row.fileSuffix) == 'audio'"/>
              <img src="../../../assets/images/other.png" v-else>
            </template>
            <span style="margin-left: 8px">{{ scope.row.name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="name"
          :label="$t('knowledgeManagement.components.associationKnowledge.el-table-column.labelType')"
          align="center"
          width="100">
        <template slot-scope="scope">
          <div class="doc-type-name" v-if="scope.row.carrier && scope.row.carrier.type">
            <span>{{ scope.row.carrier.type }}</span>
          </div>
          <div class="doc-type-name" v-else-if="scope.row.fileSuffix">
            <span>{{ scope.row.fileSuffix }}</span>
          </div>
          <template v-else>--</template>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          :label="$t('knowledgeManagement.components.associationKnowledge.el-table-column.operation')"
          align="center"
          v-if="!isAskLight()"
          width="100">
        <template slot-scope="scope">
          <el-tooltip :content="$t('knowledgeManagement.components.associationKnowledge.el-tooltip.bindName')" placement="top">
            <template v-if="scope.row.associatedTags && scope.row.associatedTags.indexOf(tagId) === -1">
              <i class="iconfont guoran-bangding"  @click="knowledgeBind('bind',scope.row._id)"></i>
            </template>
          </el-tooltip>
          <el-tooltip :content="$t('knowledgeManagement.components.associationKnowledge.el-tooltip.unbindName')" placement="top">
            <template v-if="scope.row.associatedTags && scope.row.associatedTags.indexOf(tagId) !==-1">
              <i class="iconfont guoran-jiechubangding" @click="knowledgeBind('unbind',scope.row._id)"></i>
            </template>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <table-pagination :pageSize="pageSize" :currentPage="currentPage" :total="tableTotal" v-if="knowledgeList.length > 0" @currentChange="handleCurrentChange"></table-pagination>
    <popup-com @closeEvent="unBindDialog = false" v-if="unBindDialog">
      <div slot="popup-name">{{ $t('knowledgeManagement.components.associationKnowledge.popup.title') }}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.components.associationKnowledge.popup.content') }}</div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="unBindDialog = false" class="cancel-btn">{{ $t('knowledgeManagement.components.associationKnowledge.popup.cancelButton') }}</el-button>
        <el-button  type="primary" size="small" @click="knowledgeUnBind"  class="confirm-btn">{{ $t('knowledgeManagement.components.associationKnowledge.popup.confirmButton') }}</el-button>
      </div>
    </popup-com>
  </div>
</template>

<script>
import { requestUrl } from "@/http/requestUrl";
import tablePagination from "@/components/pagination"
import PopupCom from "@/components/popup";
import { GetQueryString } from "@/assets/js/filterTree";
export default {
  name: "associationKnowledge",
  data(){
    return{
      knowledgeList:[],
      currentPage:1,
      pageSize:20,
      tagId:"",
      tableTotal:0,
      unBindDialog:false,
      unBindKnowledgeId:""
    }
  },
  components:{
    PopupCom,
    tablePagination
  },
  methods:{
    setSrc(type){
      if (type === 'mp4' || type === 'MP4' || type === 'mov' || type === 'MOV' || type == 'avi' || type == 'flv'){
        return 'video'
      } else if (type === 'jpg' || type === 'png' || type === 'JPG' || type === 'PNG' ||
          type === 'jpeg' || type === 'JPEG' || type === 'gif' || type === 'bmp' ||
          type === 'BMP' || type === 'WebP' || type === 'webp' || type === 'svg'  || type === 'SVG' || type === 'rgb' || type === 'heic'){
        return 'image'
      } else if (type === 'doc' || type === 'docx' || type.includes('文档')){
        return 'word'
      } else if (type === 'pdf'){
        return 'pdf'
      } else if (type === 'ppt' || type === 'pptx'){
        return 'ppt'
      } else if (type === 'xlsx' || type === 'xls'){
        return 'excel'
      } else if (type === 'txt'){
        return 'txt'
      } else if (type === 'mp3' || type === 'MP3'){
        return 'audio'
      } else {
        return type
      }
    },
    getKnowledge(tagId,folderTree){
      this.tagId = tagId;
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      let corpId = sessionStorage.getItem('_corpId');
      let mainId = sessionStorage.getItem('_mainId');
      if (isAskLightning){
        let memberInDepartDto = JSON.parse(localStorage.getItem('memberInDepartDto'));
        let url = requestUrl.knowledgeBase.clientSelectFileTags;
        let id = folderTree[0] ? folderTree[0].id : '';
        console.log('id',id)
        url += '?page=' + this.currentPage + '&size=' + this.pageSize + '&corpId=' + corpId + '&mainId=' + mainId + '&tagId=' + tagId;
        this.$http.post(url,memberInDepartDto).then(res => {
          if (res.data.code == 0 && res.data.data){
            this.knowledgeList = res.data.data.list;
            this.tableTotal = res.data.data.total;
          }
        })
      } else {
        let url = requestUrl.tagManage.getAssociationKnowledge + '?id=' + tagId + '&page=' + this.currentPage + '&pageSize=' + this.pageSize;
        this.$http.get(url).then(res => {
          console.log(res)
          if (res.data.code == 0){
            this.knowledgeList = res.data.data.list;
            this.tableTotal = res.data.data.total;
          }
        })
      }
    },
    knowledgeBind(type,id){
      this.unBindKnowledgeId = id;
      if (type == 'unbind'){
        this.unBindDialog = true;
      }
    },
    knowledgeUnBind(){
      let url = requestUrl.tagManage.unBindTag + this.unBindKnowledgeId;
      let tagIds = {
        tagIds:[this.tagId]
      }
      this.$http.put(url,tagIds).then(res => {
        if (res.data.code == 0){
          this.$message({
            message:this.$t('knowledgeManagement.components.associationKnowledge.methods.knowledgeUnBindMessage'),
            type:"success"
          })
          this.updateCount(this.tagId)
          this.unBindDialog = false;
          this.$emit('knowledgeUnbind',this.unBindKnowledgeId)
          this.getKnowledge(this.tagId);
        }
      })
    },
    updateCount(tagList){
      let url = requestUrl.tagManage.updateTagCount;
      let obj = {
        mainId: sessionStorage.getItem('_mainId'),
        corpId:sessionStorage.getItem('_corpId'),
        tagList,
      }
      this.$http.post(url,obj).then(() => {
        console.log('')
      })
    },
    handleCurrentChange(val){
      this.currentPage = val;
      this.getKnowledge(this.tagId);
    }
  },
  mounted() {
  }
};
</script>

<style scoped lang="less">
.associationKnowledge{
  position: relative;
  height: 100%;
  .doc-name{
    display: flex;
    align-items: center;
    max-width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .doc-type-name{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i{
    cursor: pointer;
    color: #366aff;
  }
}
</style>
import { render, staticRenderFns } from "./associatedTrees.vue?vue&type=template&id=1c1f8334&scoped=true"
import script from "./associatedTrees.vue?vue&type=script&lang=js"
export * from "./associatedTrees.vue?vue&type=script&lang=js"
import style0 from "./associatedTrees.vue?vue&type=style&index=0&id=1c1f8334&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1f8334",
  null
  
)

export default component.exports
<template>
  <div class="vue-office-txt">
    <div class="vue-office-txt-main" id="vue-office-txt-main" ref="rootRef"></div>
  </div>
</template>

<script>
import newDocx from "@/components/preview/doc/docx";
import { isUtf8 } from "@/assets/js/common";
import waterMark from "@/utils/common";
import { mapGetters } from 'vuex'
export default {
  name: "vueTxt",
  data(){
    return{

    }
  },
  props:{
    src:{
      type:String,
      default:""
    },
    requestOptions:{
      type:Object,
      default(){
        return {}
      }
    },
    options:{
      type:Object,
      default(){
        return {}
      }
    },
    editType:{
      type:String,
      default:""
    }
  },
  computed:{
    ...mapGetters('knowledge', {
      textWatermarkStr: 'getTextWatermarkStr'
    }),
  },
  methods:{
    init(){
      let fileData = null;
      let container = this.$refs.rootRef;
      let src = this.src
      if (this.editType !== 'copyNewVersion'){
        src += '?timestamp=' + new Date().getTime();
      }
      newDocx.getData(src, this.requestOptions).then(async res => {
        fileData = await newDocx.getBlob(res);
        const val = fileData;
        const reader = new FileReader();
        const that = this;
        let isU8 = await isUtf8(fileData);
        if (isU8 !== 'utf-8'){
          reader.readAsText(val,'gb2312');
        } else {
          reader.readAsText(val,'utf8');
        }
        console.log('reader',reader)
        reader.onload = function () {
          const valHtml = reader.result.replace(/\n/g,'<br>')
          if (that.$refs.rootRef){
            that.$refs.rootRef.innerHTML = valHtml || '';
          }
          if (that.textWatermarkStr){
            waterMark.newInitWaterMark('vue-office-txt-main',that.textWatermarkStr)
          }
        }
      }).catch(e => {
        newDocx.render('', container, this.options);
        console.log('e',e)
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  }
};
</script>

<style scoped lang="less">
.vue-office-txt{
  //height: 100%;
  //overflow: hidden;
  text-align: left;
  background-color: #ffffff;
  border-radius: 5px;
  .vue-office-txt-main{
    padding: 15px;
    //height: calc(100% - 30px);
    //overflow-y: scroll;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    word-break: break-word;
  }
}
</style>
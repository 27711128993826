<!--关联同步的弹框-->
<template>
  <div id="associatedTree">
    <div class="tree-header">
        <div class="tree-header-name">{{ $t('knowledgeManagement.associatedTree.treeHeader.name') }}</div>
        <div class="tree-header-type">{{ $t('knowledgeManagement.associatedTree.treeHeader.type') }}</div>
        <div class="tree-header-posiiton">{{ $t('knowledgeManagement.associatedTree.treeHeader.position') }} <span>{{ $t('knowledgeManagement.associatedTree.treeHeader.positionTips') }}</span> </div>
        <div class="tree-header-limit">{{ $t('knowledgeManagement.associatedTree.treeHeader.accessPermission') }}</div>
        <div class="tree-header-operation">{{ $t('knowledgeManagement.associatedTree.treeHeader.operation') }}</div>
    </div>
    <el-tree
      ref="tree"
      class="file-tree"
      node-key="id"
      :expand-on-click-node="false"
      :load="loadNode"
      lazy
      :props="{id:'id', children: 'children',name: 'name',isLeaf:'isLeaf'}">
      <template  slot-scope="{ data }">
        <div class="custom-tree-node tree-item">
          <div class="left-name">
              <div class="tree-item-name" >
                <div class="icon-box"> <i class="iconfont guoran-tongyichicun-zhishiku"></i></div>
                <el-tooltip v-if="data.showTooltip" class="item" effect="dark" :content="data.name" placement="top-start">
                  <div class="folder-name one-column-ellipsis" @mouseenter="mouseenterFn($event,data)">{{ data.name }}</div>
                </el-tooltip>
                <div v-else class="folder-name" @mouseenter="mouseenterFn($event,data)">{{ data.name }}</div>
              </div>
          </div>
          <div class="right-box">
            <div class="tree-item-type">
              <template v-if="data.type == 0">
                <img src="../../assets/images/file.png" class="folder-icon">
                <span>{{ $t('knowledgeManagement.associatedTree.folderType.folder') }}</span>
              </template>
              <template v-else>
                  <img src="../../assets/images/video.png"
                      v-if="setSrc(data.fileSuffix) == 'video'" />
                  <img src="../../assets/images/img.png"
                      v-else-if="setSrc(data.fileSuffix) == 'image'" />
                  <img src="../../assets/images/txt.png"
                      v-else-if="setSrc(data.fileSuffix) == 'txt'" />
                  <img src="../../assets/images/doc.png"
                      v-else-if="setSrc(data.fileSuffix) == 'word' || (data.fileSuffix && data.fileSuffix.includes('文档') && data.type == 1)" />
                  <img src="../../assets/images/pdf.png"
                      v-else-if="setSrc(data.fileSuffix) == 'pdf'" />
                  <img src="../../assets/images/ppt.png"
                      v-else-if="setSrc(data.fileSuffix) == 'ppt'" />
                  <img src="../../assets/images/xls.png"
                      v-else-if="setSrc(data.fileSuffix) == 'excel'" />
                  <img src="../../assets/images/html.png"
                      v-else-if="setSrc(data.fileSuffix) == 'html'" />
                  <img src="../../assets/images/mp3.png"
                      v-else-if="setSrc(data.fileSuffix) == 'audio'"/>
                  <img src="../../assets/images/other.png" v-else>
                  <span>{{ data.fileSuffix.replace('.',"") }}</span>
              </template>
            </div>
            <div class="tree-item-posiiton">
              <el-dropdown
                trigger="click"
                id="el-dropdown-table-dir"
                :ref="'tableDrop' + data.id"
                @visible-change="elDropdownvisibleChange($event,data)"
            >
                <div
                    :class="['el-dropdown-link',
                    'el-dropdown-link-dir',
                    directorySelect
                    ? 'active-el-dropdown-link': '',]">
                  <div
                      v-if="data.parentDirectoryName"
                      class="el-dropdown-dir-content"
                  >
                    <el-tooltip v-if="data.showTooltip" class="item" effect="dark" :content="data.parentDirectoryName" placement="top-start">
                      <div class="folder-name" @mouseenter="mouseenterFn($event,data)">
                        {{ data.parentDirectoryName }}
                      </div>
                    </el-tooltip>
                    <div v-else class="folder-name" @mouseenter="mouseenterFn($event,data)">
                      {{ data.parentDirectoryName }}
                    </div>
                  </div>
                  <span
                      v-else
                      class="el-dropdown-placeholder"
                  >{{data.placeholder ? data.placeholder : "请选择"}}</span>
                  <div class="operateBtn">
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.associatedTree.tooltip.allUsed')" placement="top-start">
                      <i class="iconfont guoran-quanbu1" @click.stop="userAll('seat',data)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.associatedTree.tooltip.pastePosition')" placement="top-start">
                      <i class="iconfont guoran-niantie" @click.stop="paste('seat',data)"></i>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.associatedTree.tooltip.copyPosition')" placement="top-start">
                      <i class="iconfont guoran-fuzhi" @click.stop="duplicateData('seat',data)"></i>
                    </el-tooltip>
                  </div>
                </div>
              <el-dropdown-menu
                  id="table-dir-dropdown"
                  slot="dropdown"
              >
                <el-dropdown-item
                    class="dir-dropdown-item"
                >
                  <div class="dir-tree">
                    <el-tree
                        :data="directoryTree"
                        node-key="id"
                        :default-expanded-keys="[-1]"
                        :expand-on-click-node="false"
                        check-strictly
                        :props="typeTreeDefaultProps"
                        lazy
                        :load="loadDireNode"
                    >
                        <span class="custom-tree-node" slot-scope="scope" @click="nodeClick(scope.node,scope.data,data)">
                          <div class="custom-tree-node-left">
                            <span class="custom-tree-node-label">{{ scope.data.name }}</span>
                          </div>
                          <span class="node-right" v-if="scope.data.parentId !== -1">
                            <i class="iconfont guoran-tongyichicun-tianjiatongji" @click.stop="addFolder('same',scope.node,scope.data,data.id)"></i>
                            <i class="iconfont guoran-tongyichicun-tianjiaxiaji" @click.stop="addFolder('child',scope.node,scope.data,data.id)"></i>
                          </span>
                          <span v-if="scope.data.checked" class="check-status"><i class="el-icon-check"></i></span>
                        </span>
                    </el-tree>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="tree-item-limit">
              <div class="accessRightsBox">
              <div class="permission" @click.stop="selectPermission(data)">
                <template v-if="data.visitPermissionList && data.visitPermissionList.length > 0">
                  <span style="color: #04BB82">{{ $t('knowledgeManagement.folderManagement.setUp') }}</span>
                </template>
                <template v-else>
                  <span style="color: #366aff">
                    {{ $t('knowledgeManagement.folderManagement.notSet') }}
                  </span>
                </template>
                </div>
                <div class="operateBtn">
                  <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.UseAll')" placement="top-start">
                    <i class="iconfont guoran-quanbu1" @click.stop="userAll('authority',data)"></i>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.paste')" placement="top-start">
                    <i class="iconfont guoran-niantie" @click.stop="paste('authority',data)"></i>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="$t('knowledgeManagement.folderManagement.copy')" placement="top-start">
                    <i class="iconfont guoran-fuzhi" @click.stop="duplicateData('authority',data)"></i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="tree-item-operation">
              <i class="iconfont guoran-quxiao" @click.stop="deleteFolder(scope.row)"></i>
            </div>
          </div>
        </div>
      </template>
    </el-tree>
    <div class="chooseFolder" @click="chooseFolder">
      <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
      <span>{{ $t('knowledgeManagement.associatedTree.chooseFolder.text') }}</span>
    </div>
    <div class="footer" v-if="false">
      <el-switch
          v-model="overwriteFile"
          active-color="#366aff">
      </el-switch>
      <span class="title">{{ $t('knowledgeManagement.associatedTree.footerTitle.overwriteFiles') }}</span>
      <span class="tips">{{ $t('knowledgeManagement.associatedTree.footerTitle.tips') }}</span>
    </div>
    <popup @closeEvent='folderDialog = false' v-if="folderDialog" class="folderDialog">
      <div slot="popup-name">{{folderTitle}}</div>
      <div slot="popup-tip">{{ $t('knowledgeManagement.associatedTree.folderDialogTip.createFolder') }}</div>
      <div slot="popup-con-c" class="popup-con-c">
        <folder-form :directory-tree="directoryTree" :formFolder="formFolder" ref="folderForm" :currentNode="currentData"></folder-form>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="folderDialog = false" class="cancel-btn">{{ $t('knowledgeManagement.folderManagement.cancel') }}</el-button>
        <el-button  type="primary" size="small" @click="confirmFolder"  class="confirm-btn">{{ $t('knowledgeManagement.folderManagement.confirm') }}</el-button>
      </div>
    </popup>
    <employee-structure
        ref="employeeStructure"
        :permissionList="permissionList"
        @confirmPermission="confirmPermission"
        @cancelPermission="cancelPermission"
        @onNodeClick="onNodeClick"
        v-if="false"
        class="folderDialog"
    ></employee-structure>
    <knowledge-permissions
        ref="knowledgePermissions"
        v-if="permissionDialog"
        class="folderDialog"
        @confirmKnowledgePermissions="confirmPermission"
    ></knowledge-permissions>
  </div>
</template>

<script>
import Popup from "@/components/popup";
import FolderForm from "@/views/knowledgeManagement/components/folderForm";
import { requestUrl } from "@/http/requestUrl";
import EmployeeStructure from "@/components/employeeStructure";
// import openData from "@/components/openData";
import { GetQueryString, platform } from "@/assets/js/filterTree";
import KnowledgePermissions from "@/components/knowledgePermissions";
export default {
  name: "associatedTree",
  components: { KnowledgePermissions, EmployeeStructure, FolderForm, Popup },
  data(){
    return{
      folderTree:[],
      directorySelect:false,
      directoryTree:[],
      typeTreeDefaultProps:{
        label:"name",
        isLeaf:'leaf'
      },
      value:false,
      folderDialog:false,
      folderTitle:"",
      formFolder:{
        name:"",
        description:"",
        parentId:"",
        takingEffectTimeType:"noLimit",
        takingEffectTime:[]
      },
      parentDirectorySelect:"",
      parentDirectoryName:"",
      tableDataId:"",
      currentData:{},
      permissionDialog:false,
      permissionList:[],
      checkNode:{},
      overwriteFile:true,
      duplicate:{},
      currentNode:null,
      rowData:null,
    }
  },
  props:["checkFolder","channel"],
  methods:{
    loadDireNode(node, resolve) {
      console.debug('node',node)
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            if (this.rowData){
              this.setChecked(this.rowData.parentId,axiosDatas);
            }
            this.directoryTree = axiosDatas;
            return resolve(this.directoryTree);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              element.checked=false;
            });
            if (this.rowData){
              this.setChecked(this.rowData.parentId,axiosDatas);
            }
            node.data.children = axiosDatas;
            return resolve(axiosDatas);
          }
        })
      }
    },
    /*删除同步文件/文件夹*/
    deleteFolder(row){
      this.$emit('deleteFolder',row)
      this.deleteCheckData(this.checkFolderCopy,row);
    },
    deleteCheckData(list,row){
      for (let i =0;i<list.length;i++){
        if (list[i].id == row.id){
          list.splice(i,1)
          break;
        }
        if (list[i].children){
          this.deleteCheckData(list[i].children,row)
        }
      }
    },
    chooseFolder(){
      this.$emit('chooseFolder',this.checkFolderCopy)
    },
    confirmFolder(){
      let url = requestUrl.knowledgeBase.saveKnowledge;
      let corpId = sessionStorage.getItem('_corpId')
      let visitPermission = this.$refs.folderForm.permissionList;
      let managePermissionList = this.$refs.folderForm.$refs.accountManagement.managePermission;
      let validFrom = null;
      let validTo = null;
      if (this.formFolder.takingEffectTime && this.formFolder.takingEffectTime.length > 0){
        validFrom = this.formFolder.takingEffectTime[0];
        validTo = this.formFolder.takingEffectTime[1]
      }
      let obj = {
        corpId:corpId,
        parentId:this.formFolder.parentId,
        name:this.formFolder.name,
        description:this.formFolder.description,
        orderNum:this.$refs.folderForm.parentChildrenNum,
        type:0,
        visitPermissionList:visitPermission.map(res => {
          let obj = {
            id:res.id,
            type:res.type !== undefined ? res.type : (res.source !== undefined ? 0 : 1),
            name:res.name ? res.name : res.label
          }
          return obj
        }),
        managePermissionList,
        textWatermark:this.$refs.folderForm.formFolders.textWatermark,
        textWatermarkValue:this.$refs.folderForm.formFolders.textWatermarkValue,
        isDownload:this.$refs.folderForm.formFolders.isDownload,
        downloadType:this.$refs.folderForm.formFolders.downloadType,
        validFrom,
        validTo
      };
      if (!obj.name){
        this.$message({
          message:"文件夹名称是必填的",
          duration:2000,
          type:'warning'
        })
        return
      }
      if (obj.visitPermissionList.length == 0){
        this.$message({
          message:"请选择可阅读权限",
          duration:2000,
          type:'warning'
        })
        return
      }
      this.$http.post(url,obj).then(res => {
        if (res.data.code === '0'){
          this.$message({
            message:"新增文件夹成功",
            type:"success",
            duration:2000
          })
          // this.getKnowledgeBaseList('');
          this.folderDialog = false;
        }
      })
    },
    elDropdownvisibleChange(value,row){
      this.rowData = row;
      this.parentDirectorySelect = value;
    },
    nodeClick(node,data,row){
      console.log(node,data,row,'node,data,row');
      this.$set(data, "checked", true);
      let arr = platform(node.parent);
      arr.push(data.name)
      let name = arr.join('/')
      this.$set(row,'parentDirectoryName',name)
      row.parentId = data.id;
      let messageDrop = this.$refs['tableDrop' + row.id];
      if (messageDrop) {
        messageDrop.visible = false;
      }
    },
    addFolder(type,node,data,id){
      this.folderDialog = true;
      this.currentData = data;
      let messageDrop = this.$refs['tableDrop' + id];
      if (messageDrop) {
        messageDrop.visible = false;
      }
      this.formFolder = {
        name:"",
        description:"",
        parentId:"",
        takingEffectTimeType:"noLimit",
        takingEffectTime:""
      }
      this.$nextTick(() => {
        if (type === 'same'){
          this.formFolder.parentId = data.parentId;
          this.folderTitle = "新建同级文件夹";
          this.$nextTick(() => {
            this.$refs.folderForm.parentDirectoryName = node.parent.data.name;
            this.$refs.folderForm.parentChildrenNum = node.parent.data.children.length;
          })
        } else {
          this.formFolder.parentId = data.id;
          this.folderTitle = "新建子级文件夹"
          this.$nextTick(() => {
            this.$refs.folderForm.parentDirectoryName = data.name;
            this.$refs.folderForm.parentChildrenNum = data.children.length;
          })
        }
      })

    },
    getKnowledgeBaseList(departId,data){
      let corpId = sessionStorage.getItem('_corpId');
      let url = requestUrl.knowledgeBase.knowledgeBaseTree + "?corpId=" + corpId + '&parentId=' + (data ? data.id : -1);
      this.$http.get(url).then(res => {
        this.directoryTree = res.data.data ? res.data.data : []
        console.log(this.directoryTree,'directoryTree');
        // let axiosDatas = [];
        // axiosDatas = res.data.data.map(res => {
        //   res.showChildren = false;
        //   res.checked = false;
        //   return res
        // });
        // if (res.data.code == '0'){
        //   if (departId){
        //     data.children = [...data.children,...axiosDatas];
        //     data.showChildren = true;
        //   } else {
        //     this.directoryTree[0].children = [...axiosDatas];
        //   }
        //   this.directoryTree[0].children.forEach(item => {
        //     if(!departId){
        //       item.showChildren = false;
        //       item.children = [];
        //     }
        //   })
        // }
      })
    },

    setChecked(id,list) {
      list.forEach(item => {
        this.$set(item, "checked", false);
        if (item.children){
          this.setChecked(id,item.children)
        }
      });
      list.forEach(item => {
        if (item.id === id) {
          this.$set(item, "checked", true);
        }
        if(item.children){
          this.setChecked(id,item.children)
        }
      });
    },
    // loadNode(node,resolve){
    //   console.log(node,'node');
    //   node.data[0].showChildren = !node.data[0].showChildren;
    //   if(node.data[0].showChildren){
    //     this.nodeExpand(node.data[0],node,resolve);
    //   } else {
    //     node.data[0].children = [];
    //   }
    // },
    loadNode(node, resolve){
      let treeData =  node.data || {};
      if(node.level === 0){
        resolve(this.checkFolderCopy);
      } else {
        let data = []
          let corpId = sessionStorage.getItem('_corpId')
          let  url = requestUrl.yuQueKnowledgeBase.selectSyncList
          if(this.channel === 5){
            url = requestUrl.feishu.selectSyncListWithFilter + "?corpId=" + corpId + '&parentToken=' + (treeData ? treeData.token : -1);
          } else {
            url += "?corpId=" + corpId + '&parentId=' + (treeData ? treeData.id : -1) + '&justFolder=false';
          }
          this.$http.get(url).then(res => {
          if (res.data.code == '0' && res.data.data){
            if (res.data.data.length == 0){
              // this.$set(node,'isLeaf',true)
              resolve([])
            } else {
              data = res.data.data.map(ress => {
                ress.placeholder = "默认跟随父节点的选择";
                ress.showTooltip = false;
                if(ress.type === 1){
                  ress.isLeaf = true;
                } else {
                  ress.isLeaf = false;
                }
                if (!ress.visitPermissionList){
                  ress.visitPermissionList = [];
                }
                if (!ress.parentDirectoryName){
                  ress.parentDirectoryName = '';
                  ress.parentId = "";
                }
                ress.visitPermissionList = treeData.visitPermissionList;
                return ress
              })
              if(this.channel === 5){
                data.forEach(item => {
                  item.fileSuffix = item.fileType;
                  let visitPermissionList = JSON.parse(item.visitPermission) || []
                  visitPermissionList.forEach(vis => {
                    vis.label = vis.name;
                  })
                  item.visitPermissionList = visitPermissionList;
                })

              }
              node.data.children = data;
              resolve(data)
            }
          }
        })
      }
    },
    //打开选择可访问权限
    selectPermission(row){
      this.permissionDialog = true;
      this.checkNode = row;
      this.permissionList = row.visitPermissionList ? JSON.parse(JSON.stringify(row.visitPermissionList)) : [];
      this.$nextTick(() => {
        // this.$refs.employeeStructure.permissionDialog = true;
        this.$refs.knowledgePermissions.permissionsObj.permissionsList = row.visitPermissionList ? JSON.parse(JSON.stringify(row.visitPermissionList)) : [];
        this.$refs.knowledgePermissions.permissionsListCopy = row.visitPermissionList ? JSON.stringify(row.visitPermissionList) : "[]";
        this.$refs.knowledgePermissions.knowledgePermissions = true;
        this.$refs.knowledgePermissions.permissionsObj.textWatermark = row.textWatermark;
        this.$refs.knowledgePermissions.permissionsObj.textWatermarkValue = row.textWatermarkValue;
        this.$refs.knowledgePermissions.permissionsObj.isDownload = row.isDownload;
        this.$refs.knowledgePermissions.permissionsObj.downloadType = row.downloadType;
        let management = []
        if (row.managePermission){
          management = JSON.parse(JSON.stringify(row.managePermission));
        }
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermission = management;
        this.$refs.knowledgePermissions.$refs.accountManagement.managePermissionIds = management.map(res => {
          return res.id
        })
      })

    },
    delPermission(row,index){
      row.visitPermissionList.splice(index,1);
    },
    cancelPermission(){
      this.$refs.employeeStructure.permissionDialog = false;
      this.permissionDialog = false;
    },
    confirmPermission(obj){
      this.$refs.knowledgePermissions.knowledgePermissions = false;
      this.permissionDialog = false;
      this.checkNode.visitPermissionList = obj.permissionsList;
      this.checkNode.managePermission = obj.accountManagement;
      this.checkNode.textWatermark = obj.textWatermark;
      this.checkNode.textWatermarkValue = obj.textWatermarkValue;
      this.checkNode.isDownload = obj.isDownload;
      this.checkNode.downloadType = obj.downloadType;
    },
    onNodeClick(data){
      if (data.checked){
        this.permissionList.push(data)
      } else {
        this.permissionList = this.permissionList.filter(item => {
          return item.id !== data.id
        });
      }

    },
    /**
     * 使用到全部  包括位置和权限
     * 使用type区分是位置还是权限
     * */
    userAll(type,row){
      this.selectedData(type,this.checkFolderCopy,row)
    },
    /**
     * paste 粘贴复制的位置
     * */
    paste(type,row){
      if (Object.keys(this.duplicate).length == 0){
        this.$message({
          message:"请先复制内容",
          type:"warning",
          duration:2000
        })
        return
      }
      if (type === 'seat'){
        row.parentId = this.duplicate.parentId;
        row.parentDirectoryName = this.duplicate.parentDirectoryName;
      }
      if (type === 'authority'){
        row.visitPermissionList = this.duplicate.visitPermissionList;
      }
    },
    /**
     * duplicateData 复制位置或者权限
     * */
    duplicateData(type,row){
      this.duplicate = row;
    },
    selectedData(type,list,data){
      list.forEach(item => {
        if (type == 'seat'){
          item.parentId = data.parentId;
          item.parentDirectoryName = data.parentDirectoryName;
          let messageDrop = this.$refs['tableDrop' + item.id];
          let dataMessageDrop = this.$refs['tableDrop' + data.id];
          if (messageDrop) {
            messageDrop.visible = false;
          }
          if (dataMessageDrop){
            dataMessageDrop.visible = false;
          }
        }
        if (type == 'authority'){
          item.visitPermissionList = JSON.parse(JSON.stringify(data.visitPermissionList));
        }
        if (item.children){
          this.selectedData(type,item.children,data)
        }
      })
    },
    setSrc(type){
      if (type === 'mp4' || type === 'MP4' || type === 'mov' || type === 'MOV' || type == 'avi' || type == 'flv'){
        return 'video'
      } else if (type === 'jpg' || type === 'png' || type === 'JPG' || type === 'PNG' ||
          type === 'jpeg' || type === 'JPEG' || type === 'gif' || type === 'bmp' ||
          type === 'BMP' || type === 'WebP' || type === 'webp' || type === 'svg'  || type === 'SVG' || type === 'rgb' || type === 'heic'){
        return 'image'
      } else if (type === 'doc' || type === 'docx' || type.includes('文档')){
        return 'word'
      } else if (type === 'pdf'){
        return 'pdf'
      } else if (type === 'ppt' || type === 'pptx'){
        return 'ppt'
      } else if (type === 'xlsx' || type === 'xls'){
        return 'excel'
      } else if (type === 'txt'){
        return 'txt'
      } else if (type === 'mp3' || type === 'MP3'){
        return 'audio'
      } else {
        return type
      }
    },
    mouseenterFn(e,data){
      if(e.target.scrollWidth > e.target.offsetWidth){
        this.$set(data,'showTooltip',true)
      }

    },
  },
  // watch:{
  //   checkFolder:{
  //     handler(n){
  //       console.log(n,'mm');

  //     },
  //     deep:true,
  //     immediate:true
  //   }
  // },
  computed:{
    checkFolderCopy(){
      console.log(this.checkFolder,'this.checkFolder');
      return this.checkFolder.map(item => {
        if(item.type === 1){
          item.isLeaf = true;
        } else {
          item.isLeaf = false;
        }
        return item;
      })
      // return this.checkFolder
    }
    // this.checkFolderCopy = JSON.parse(JSON.stringify(n))
  },
  mounted() {
    // this.getKnowledgeBaseList('');
  }
};
</script>

<style scoped lang="less">
#associatedTree{
  background: #FFFFFF;
  border: 1px solid #E0E6F7;
  border-radius: 5px;
  height: 100%;
  /deep/.el-table--enable-row-hover .el-table_body tr:hover > td{
    background-color: #DBE5FF!important;
  }
  /deep/.el-table--border{
    border-color: #E0E6F7;
  }
  .el-table tr:hover{
    .operateBtn{
      display: flex;
    }
  }

  .header{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 0!important;
    .tips{
      color: #A9B3C6;
      margin-left: 4px;
      font-size: 12px;
    }
  }
  .operateBtn{
    flex: none;
    width: 60px;
    display: none;
    align-items: center;
    justify-content: space-between;
    //margin-right: 10px;
    i{
      color: #366aff;
      cursor: pointer;
    }
  }
  #el-dropdown-dir,#el-dropdown-table-dir{
    width: 100%;
    .el-dropdown-link-dir{
      display: flex;
      align-items: center;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      height: 36px;
      padding: 0px 12px;
      justify-content: space-between;
      background-color: #fff;
      .el-dropdown-placeholder{
        flex: auto;
        display: flex;
        //height: 38px;
        //padding: 7px 12px;
        color: #C0C4CC;
        align-items: center;
      }
      .el-dropdown-dir-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -wenkit-line-clamp:1;
        text-align: center;
        .el-tag {
          margin: 2px 5px 0 0;
        }
        .folder-name{
          //max-width: 220px;
          text-overflow: ellipsis;
          // display: -webkit-box; //使用了flex，需要加
          overflow: hidden;     //超出隐藏
          word-break: break-all; //纯英文、数字、中文
          text-overflow: ellipsis;  //省略号
          -webkit-box-orient: vertical; //垂直
          -webkit-line-clamp: 1;  //显示一行
          white-space:inherit;   //合并空白
        }
      }
      .el-icon{
        flex: none;
        width: 30px;
        text-align: center;
        display: flex;
        align-items: center;
      }
    }
  }
  .accessRightsBox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border: 1px solid #E0E6F7;
    border-radius: 5px;
    padding: 0px 12px;
    height: 34px;
    overflow: hidden;
    .permission{
      flex: auto;
      display: flex;
      align-items: center;
      //flex-wrap: wrap;
      width: calc(100% - 24px);
      overflow: hidden;
      .permissionItem-list{
        display: flex;
        align-items: center;
        .permissionItem-num{
          padding-right: 8px;
          color: #010101;
          font-size: 14px;
        }
      }
      .permissionItem{
        display: flex;
        align-items: center;
        margin-right: 8px;
        background-color: #E8EDF7;
        border-radius: 3px;
        padding: 0px 4px;
        font-size: 12px;
        color: #010101;
        height: 26px;
        .avatar{
          flex: none;
          width: 21px;
          height: 21px;
          background: #366AFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 12px;
        }
        .departAvatar{
          flex: none;
          width: 21px;
          height:21px;
          background: #918EFF;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
          i{
            color: #FFFFFF;
            font-size: 12px;
          }
        }
        .label{
          padding: 0px 4px;
          max-width: 140px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-top: 0;
        }
      }
    }
  }
  .guoran-quxiao{
    color: #366aff;
    cursor: pointer;
  }
  .chooseFolder{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    color: #366aff;
    border-top: 1px solid #E0E6F7;
    // border-top: none;
    cursor: pointer;
    i{
      margin-right: 8px;
      font-size: 14px;
    }
  }
  .footer{
    display: flex;
    align-items: center;
    margin-top: 20px;
    .title{
      color: #616161;
      margin-left: 8px;
    }
    .tips{
      color: #A9B3C6;
      margin-left: 8px;
      font-size: 13px;
    }
  }
  .tree-header{
    display: flex;
    align-items: center;
    height: 40px;
    background: #F5F7FB;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    padding: 0 10px;
    border-bottom: 1px solid #E0E6F7;
    // width: calc(100% - 20px);
    .tree-header-posiiton,.tree-header-limit{
      span{
        font-size: 12px;
        color: #A9B3C6;
      }
    }
  }
  .tree-header-name{
    // min-width: 200px;
    width: 280px;
    // flex: 1;
  }
  .tree-header-type,.tree-item-type{
    width: 100px;
  }
  .tree-header-posiiton,.tree-header-limit, .tree-item-posiiton,.tree-item-limit{
    // min-width: 260px;
    width:250px;
  }
  .tree-header-limit,.tree-item-limit{
    margin: 0 10px;
  }
  .tree-header-operation,.tree-item-operation{
    width: 60px;
    text-align: center;
  }
  /deep/.file-tree{
    height: 402px;
    // overflow-y: hidden;
    overflow: auto;
   .el-tree-node__content {
      height: 60px;
      line-height: 60px;
      // padding-left: 5px !important;
    }
    .el-tree-node {
      position: relative;
      .el-tree-node__children {
        padding-left: 20px; // 缩进量
      }
       // 竖线
      &::before {
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        left: -3px;
        top: -30px;
        border-width: 1px;
        border-left: 1px dashed #4A7FEB;
      }
      // 当前层最后一个节点的竖线高度固定
      &:last-child::before {
        height: 56px; // 可以自己调节到合适数值
      }
      // 横线
      &::after {
        content: "";
        width:9px;
        height: 20px;
        position: absolute;
        left: -3px;
        top: 25px;
        border-width: 1px;
        border-top: 1px dashed #4A7FEB;
      }
      .el-tree-node__content{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50px;
        color: #000;
        padding-left: 0 !important;
        border-bottom: 1px solid #E0E6F7;
        .el-tree-node__expand-icon{
            display: inline-block;
            color: #366AFF;
            border: 1px solid #C1D3F8;
            border-radius: 1px;
            text-align: center;
            line-height: 13px;
            height: 13px;
            width: 13px;
            margin-left: 6px;
            padding: 0;
            // display: flex;
            // align-items: center;
            // font-size: 16px;
            &.is-leaf{
              display: none;
            }

        }
        .custom-tree-node{
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px;
          color: #000;
          // padding-left: 6px;
          .left-name{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            max-width: 220px;
          }
          .right-box{
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          .tree-item-name{
            display: flex;
            align-items: center;

            .icon-box{
              flex-shrink: 0;
              display: inline-block;
              width: 22px;
              height: 22px;
              background: #6F94FF;
              border-radius: 50%;
              text-align: center;
              line-height: 21px;
              margin-left: 6px;
              i{
                color: #ffff;
                font-size: 12px;
              }
            }

            .folder-name{
              margin-left: 4px;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // white-space: nowrap;
              color: #000000;
              max-width: 220px;
              text-overflow: ellipsis;
              // display: -webkit-box; //使用了flex，需要加
              overflow: hidden;     //超出隐藏
              word-break: break-all; //纯英文、数字、中文
              text-overflow: ellipsis;  //省略号
              -webkit-box-orient: vertical; //垂直
              -webkit-line-clamp: 1;  //显示一行
              white-space:nowrap;   //合并空白
            }
          }
          .tree-item-type{
            display: flex;
            align-items: center;
            img{
              margin-right: 6px;
            }
          }
          .custom-tree-node-left{
            max-width: 130px;
            flex: none;
            margin: 10px 10px 10px 0;
            overflow: hidden;
            text-overflow:ellipsis;
            .custom-tree-node-label{
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
              max-width: 130px;
            }
          }
          &:hover{
            .operateBtn{
              display: flex;
            }
          }
        }
      }
      // &.is-expanded{
      //   .custom-tree-node{
      //     padding: 0;
      //   }
      // }

    }



    // 竖线
    // .el-tree-node::before {
    //   content: "";
    //   height: 100%;
    //   width: 1px;
    //   position: absolute;
    //   left: -3px;
    //   top: -30px;
    //   border-width: 1px;
    //   border-left: 1px dashed #4A7FEB;
    // }

    // 当前层最后一个节点的竖线高度固定
    // .el-tree-node:last-child::before {
    //   height: 56px; // 可以自己调节到合适数值
    // }

    // 横线
    // .el-tree-node::after {
    //   content: "";
    //   width:17px;
    //   height: 20px;
    //   position: absolute;
    //   left: -3px;
    //   top: 25px;
    //   border-width: 1px;
    //   border-top: 1px dashed #4A7FEB;
    // }

    // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
    & > .el-tree-node::after {
      border-top: none;
    }

    & > .el-tree-node::before {
      border-left: none;
    }

  }


}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
.dir-dropdown-item{
  padding: 0!important;
  .dir-tree{
    height: auto;
    min-width: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    ///deep/.el-tree-node__expand-icon{
    //  display: none;
    //}
    /deep/.el-tree-node__content{
      height: 32px!important;
    }
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    .custom-tree-node-left{
      display: flex;
      align-items: center;
      max-width: 300px;
      flex: none;
      .custom-tree-node-label{
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    i {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
    .node-right{
      display: none;
      align-items: center;
      justify-content: space-between;
      flex: none;
      width: 40px;
      i{
        color: #366aff;
        font-size: 13px;
      }
    }
    .el-icon-caret-right{
      padding: 0 6px;
      color: #c0c4cc;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .el-icon-caret-right{
      &.down{
        transform: rotate(90deg);
      }
      &.right{
        transform: rotate(0);
      }
    }
  }
  .custom-tree-node:hover{
    .node-right{
      display: flex;
    }
    .check-status{
      display: none;
    }
  }
  /deep/.el-tree{
    max-height: 300px;
  }
}
.folderDialog{
  /deep/.defaultDialog{
    #popup-assembly{
      width: 600px!important;
    }
  }
}
</style>
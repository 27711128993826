export const getDateRange = () => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(),23, 59, 59)
    const dayOfWeek = today.getDay();
    const month = today.getMonth();
    const year = today.getFullYear();

    const todayDateStart = new Date(today);
    const todayDateEnd = new Date(todayEnd);

    // 设置为昨天的日期
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const startOfYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 0, 0, 0);
    const endOfYesterday = new Date(yesterday.getFullYear(), yesterday.getMonth(), yesterday.getDate(), 23, 59, 59, 999);

    //最近3天开始日期
    const this3dayStart = new Date(today);
    this3dayStart.setDate(today.getDate() - 2)

    //最近3天结束日期
    const this3dayEnd = new Date(todayEnd);
    this3dayEnd.setDate(todayEnd.getDate())

    //最近7天开始日期
    const this7dayStart = new Date(today);
    this7dayStart.setDate(today.getDate() - 6)

    //最近7天结束日期
    const this7dayEnd = new Date(todayEnd);
    this7dayEnd.setDate(todayEnd.getDate())

    //最近15天开始日期
    const this15dayStart = new Date(today);
    this15dayStart.setDate(today.getDate() - 14)

    //最近15天结束日期
    const this15dayEnd = new Date(todayEnd);
    this15dayEnd.setDate(todayEnd.getDate())

    //最近30天开始日期
    const this30dayStart = new Date(today);
    this30dayStart.setDate(today.getDate() - 29)

    //最近30天结束日期
    const this30dayEnd = new Date(todayEnd);
    this30dayEnd.setDate(todayEnd.getDate())


    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);

    // 获取本周一的日期对象
    const thisWeekStart = new Date(today.setDate(diff));

    // 获取本周日的日期对象
    const thisWeekEnd = new Date(todayEnd.setDate(diff + 6));

    // 上周开始日期
    const lastWeekStart = new Date(thisWeekStart);
    lastWeekStart.setDate(thisWeekStart.getDate() - 7);

    // 上周结束日期
    const lastWeekEnd = new Date(thisWeekEnd);
    lastWeekEnd.setDate(thisWeekEnd.getDate() - 7);

    // 本月开始日期
    const thisMonthStart = new Date(year, month, 1);

    // 本月结束日期
    const thisMonthEnd = new Date(year,  month + 1, 0,23,59,59);

    // 上月开始日期
    const lastMonthStart = new Date(year, month - 1, 1);

    // 上月结束日期
    const lastMonthEnd = new Date(year,  month, 0,23,59,59);

    return {
        thisWeekStart,
        thisWeekEnd,
        lastWeekStart,
        lastWeekEnd,
        thisMonthStart,
        thisMonthEnd,
        lastMonthStart,
        lastMonthEnd,
        this30dayStart,
        this30dayEnd,
        this3dayStart,
        this3dayEnd,
        this7dayStart,
        this7dayEnd,
        this15dayStart,
        this15dayEnd,
        todayDateStart,
        todayDateEnd,
        startOfYesterday,
        endOfYesterday

    };
};

// 使用函数

<template>
  <div id="knowledgeOverview">
    <div class="header" v-if="false">
      <div class="knowledgeTab"
           v-for="(item,index) in tabList"
           :key="index"
           :class="activeTab === item.value ? 'activeKnowledgeTab' : ''"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="KnowledgeAnalysisContainer">
      <div class="cardList">
        <div class="oneRow">
          <div class="cardItem" v-for="item in oneRowCard" :key="item.key">
            <div class="cardLeft">
              <div class="cardNum">
                <span class="number">{{ item.value }}</span>
                <div class="newAdd" v-if="item.newAddNum">
                  <span>+{{item.newAddNum}}</span>
                  <div class="newAddBtn">{{$t('statistic.newAdditionsToday')}}</div>
                </div>
              </div>
              <div class="cardName">{{ $t('statistic.' + item.key) }}</div>
            </div>
            <div class="cardIcon" :style="{backgroundColor:item.bgColor,color:item.color}">
              <i :class="item.icon"></i>
            </div>
          </div>
        </div>
        <div class="twoRow">
          <div class="cardItem" v-for="item in twoRowCard" :key="item.key">
            <div class="cardLeft">
              <div class="cardNum" v-if="item.key !== 'CAPACITY'">
                <span class="number" v-if="item.key !== 'UPDATE'">{{ item.value }}</span>
                <span class="number" v-if="item.key === 'UPDATE'">{{ updateFrequency }} <span v-if="updateFrequency > 0">{{$t('statistic.article')}}</span></span>
                <div class="newAdd" v-if="item.newAddNum">
                  <span>+{{item.newAddNum}}</span>
                  <div class="newAddBtn">{{$t('statistic.newAdditionsToday')}}</div>
                </div>
              </div>
              <div class="cardNum" v-else>
                <span class="number">{{ handlerUnit(item.value) }}</span>
                <div class="newAdd" v-if="item.newAddNum">
                  <span>+{{ handlerUnit(item.newAddNum)}}</span>
                  <div class="newAddBtn">{{$t('statistic.newAdditionsToday')}}</div>
                </div>
              </div>
              <div class="cardName">{{ $t('statistic.' + item.key) }}</div>
            </div>
            <div class="cardIcon" :style="{backgroundColor:item.bgColor,color:item.color}">
              <i :class="item.icon"></i>
            </div>
          </div>
          <!-- <div class="list"></div> -->
        </div>
      </div>
      <!-- 知识问答排行 -->
      <div class="analysisCard">
        <div class="title">
          <span>{{$t('statistic.knowledgeRetrievalRanking')}}</span>
        </div>
        <div class="empty-image" v-show="overviewDataList && overviewDataList.KnowledgeSearchRanking && overviewDataList.KnowledgeSearchRanking.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
        </div>
        <div id="knowledgeSearchRanking" class="analysisCardLine" style="margin-top:15px"></div>
      </div>
      <!-- 知识编辑排行 -->
      <div class="analysisCard">
        <div class="title">
          <span>{{$t('statistic.knowledgeEditRanking')}}</span>
        </div>
        <div class="empty-image" v-show="overviewDataList && overviewDataList.KnowledgeEditRanking && overviewDataList.KnowledgeEditRanking.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
        </div>
        <div id="knowledgeEditRanking" class="analysisCardLine" style="margin-top:15px"></div>
      </div>
      <div class="pieChart">
        <!-- 知识来源占比 -->
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.sourceProportion')}}</span>
          </div>
          <div class="empty-image" v-show="overviewDataList && overviewDataList.KnowledgeSourceDistribution && overviewDataList.KnowledgeSourceDistribution.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeSourceDistribution" class="analysisCardLine"></div>
        </div>
        <!-- 知识类别占比 -->
        <div class="analysisCard">
          <div class="title">
            <span>{{$t('statistic.typeProportion')}}</span>
          </div>
          <div class="empty-image" v-show="overviewDataList && overviewDataList.KnowledgeTypeDistribution && overviewDataList.KnowledgeTypeDistribution.length === 0">
            <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
            <span class="empty-text">{{$t('statistic.empty')}}</span>
          </div>
          <div id="knowledgeTypeDistribution" class="analysisCardLine"></div>
        </div>
      </div>
      <!-- 标签关联知识排名 -->
      <div class="analysisCard">
        <div class="title">
          {{$t('statistic.tagAssociationRanking')}}
        </div>
        <div class="empty-image" v-show="overviewDataList && overviewDataList.TopAssociatedTags && overviewDataList.TopAssociatedTags.length === 0">
          <img src="https://static.guoranbot.com/cdn-arsenal/dev/img/no-data.8a0ef808.png">
          <span class="empty-text">{{$t('statistic.empty')}}</span>
        </div>
        <div id="topAssociatedTagsWordCloud" class="analysisCardLine"></div>
      </div>
    </div>
    <popup-com @closeEvent="cancelConfig" v-if="knowledgeCardDialog">
      <div slot="popup-name">{{$t('statistic.statisticalCard')}}</div>
      <div slot="popup-tip"></div>
      <div slot="popup-con" class="knowledgeCardContainer">
        <div class="card-name">
          <div class="title">{{$t('statistic.statisticalCardName')}}<span style="color: red">*</span></div>
          <el-input v-model="cardData.name" placeholder="请输入"></el-input>
        </div>
        <div class="statistics" v-for="(item,index) in cardData.list" :key="index">
          <div class="statistics-left">
            <div class="top">{{$t('statistic.statisticalIndicatorsName')}}</div>
            <div class="value">
              <el-input v-model="item.name" placeholder="请输入指标名称" maxlength="10" show-word-limit></el-input>
            </div>
          </div>
          <div class="statistics-center">
            <div class="top">{{$t('statistic.knowledgeDir')}}</div>
            <div class="value">
              <el-dropdown
                  trigger="click"
                  id="el-dropdown-dir"
                  ref="messageDrop"
                  @visible-change="elDropdownvisibleChange($event)"
                  :hide-on-click="true">
                <span
                    :class="['el-dropdown-link',
                    'el-dropdown-link-dir',
                    parentDirectorySelect
                    ? 'active-el-dropdown-link': '',]">
                  <span
                      v-if="item.catalogueName"
                      class="el-dropdown-dir-content"
                  >
                    {{ item.catalogueName }}
                  </span>
                  <span v-else class="el-dropdown-placeholder">请选择</span>
                  <span class="el-icon">
                    <em v-show="!parentDirectorySelect" class="el-icon-arrow-down"></em>
                    <em v-show="parentDirectorySelect" class="el-icon-arrow-up"></em>
                  </span>
                </span>
                <el-dropdown-menu
                    id="dir-dropdown"
                    slot="dropdown"
                >
                  <el-dropdown-item
                      class="dir-dropdown-item"
                  >
                    <div class="dir-tree">
                      <el-tree
                          :data="folderTree"
                          node-key="id"
                          :default-expanded-keys="[-1]"
                          :expand-on-click-node="false"
                          check-strictly
                          :props="typeTreeDefaultProps"
                          @node-click="nodeClick($event,item)"
                          :load="loadNode"
                          lazy
                      >
                        <span class="custom-tree-node" slot-scope="{ data }">
                          <div class="custom-tree-node-left">
                            <span class="custom-tree-node-label">
                              <span class="folder-name">{{ data.name }}</span>
                            </span>
                          </div>
                          <span v-if="data.id == item.catalogueId"><i class="el-icon-check"></i></span>
                        </span>
                      </el-tree>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="delete-icon" @click="removeStatistics(index)">
            <i class="iconfont guoran-tongyichicun-16-09-shanchu2"></i>
          </div>
        </div>
        <div class="add-statistics" @click="addStatistics">
          <div class="add-btn">
            <i class="iconfont guoran-tongyichicun-16-13-xinjian"></i>
          </div>
          <span>{{$t('statistic.addStatisticalIndicators')}}</span>
        </div>
      </div>
      <div slot="dialog-footer">
        <el-button  plain size="small" @click="cancelConfig" class="cancel-btn">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="saveConfig" class="confirm-btn">{{$t('common.confirm')}}</el-button>
      </div>
    </popup-com>
  </div>
</template>

<script>
import * as echarts from "echarts";
import 'echarts-wordcloud';
import { requestUrl } from "@/http/requestUrl";
import PopupCom from "@/components/popup";
import {  GetQueryString } from "@/assets/js/filterTree";
import { getDateRange } from "@/utils/getDataRange";
export default {
  name: "knowledgeOverview",
  data(){
    return{
      tabList:[
        {
          name:"全部知识",
          value:"all"
        }
      ],
      activeTab:"all",
      oneRowCard:[
        {
          label:"总知识数",
          value:0,
          key:"TOTAL",
          newAddNum:0,
          icon:"iconfont guoran-tongyong",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        },
        {
          label:"未发布知识数",
          value:0,
          key:"UNPUBLISH",
          icon:"iconfont guoran-tongyichicun-quxiaofabufasong",
          bgColor:"#FFF3EC",
          color:"#FF9555"
        },
        {
          label:"已发布知识数",
          value:0,
          key:"PUBLISH",
          icon:"iconfont guoran-tongyichicun-16-05-fabu",
          bgColor:"#EEECFD",
          color:"#766BF0"
        },
        {
          label:"归档知识数",
          value:0,
          key:"ARCHIVE",
          icon:"iconfont guoran-tongyichicun-yituguidangxiang1",
          bgColor:"#E1FAF9",
          color:"#09DDD5"
        },
        {
          label:"未生效知识数",
          value:0,
          key:"NOTEFFECTIVE",
          icon:"iconfont guoran-tingyong",
          bgColor:"#FFF3EC",
          color:"#FF9555"
        }
      ],
      twoRowCard:[
        {
          label:"知识占用容量",
          value:0,
          key:"CAPACITY",
          newAddNum:0,
          icon:"iconfont guoran-guanlirongliang",
          bgColor:"#EEECFD",
          color:"#766BF0"
        },
        {
          label:"日均更新频率",
          value:0,
          key:"UPDATE",
          icon:"iconfont guoran-tongyichicun-shuaxin",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        },
        {
          label:"知识总查看次数",
          value:0,
          key:"LOOK",
          icon:"iconfont guoran-tongyichicun-chakan",
          bgColor:"#E1FAF9",
          color:"#09DDD5"
        },
        {
          label:"知识总咨询次数",
          value:0,
          key:"CONSULT",
          icon:"iconfont guoran-tongyichicun-zixuncishu",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        },
        {
          label:"知识总编辑次数",
          value:0,
          key:"EDIT",
          icon:"iconfont guoran-tongyichicun-zixuncishu",
          bgColor:"#ECF1FF",
          color:"#366AFF"
        }
      ],
      classificationMyChart:null,
      consultationsMyChart:null,
      typeMyChart:null,
      associationMyChart:null,
      params:{
        mainId:"",
        corpId:"",
        userId:"",
        indicator:1
      },
      cardData:{
        name:"知识分类排名",
        list:[
          {
            name:"",
            catalogueId:"",
            catalogueName:""
          }
        ]
      },
      knowledgeInquiryRanking:{
        name:"知识咨询排名",
        list:[
          {
            name:"",
            catalogueId:"",
            catalogueName:""
          }
        ]
      },
      knowledgeEditRanking:{
        name:"知识编辑排名",
      },
      cardDataCopy:{
        name:"知识分类排名",
        list:[
          {
            name:"",
            catalogueId:"",
            catalogueName:""
          }
        ]
      },
      knowledgeCardDialog:false,
      folderTree:[],
      typeTreeDefaultProps:{
        label:'name',
        isLeaf:'leaf'
      },
      parentDirectorySelect:false,
      knowledgeAnalysis:{
        knowledgeClassification:[],
        numberOfConsultations:[],
        knowledgeType:[],
        labelAssociation:[]
      },
      companyAuditTime:null,
      updateFrequency:0,
      overviewDataList: {},
      searchRankingChart: null,
      editRankingChart: null,
      sourceDistributionChart: null,
      typeDistributionChart: null,
      tagsChart: null,
    }
  },
  components:{
    PopupCom
  },
  methods:{
    getOverviewData() {
      console.log("mainId and uid",this.params.mainId)
      this.$http.get(`/tag-api/analytics/latest/${this.params.mainId}`)
      .then((res) => {
        console.log("overviewData Response:", res);
        if (res.data.success) {
          this.overviewDataList = res.data.data;
          this.oneRowCard[0].value = res.data.data.KnowledgeTotal;
          this.oneRowCard[1].value = res.data.data.KnowledgeUnpublishedCount;
          this.oneRowCard[2].value = res.data.data.KnowledgePublishCount;
          this.oneRowCard[3].value = res.data.data.KnowledgeArchiveCount;
          this.oneRowCard[4].value = res.data.data.KnowledgeInactiveCount;
          this.twoRowCard[0].value = res.data.data.DocumentTotalVolume;
          this.twoRowCard[1].value = res.data.data.UpdateFrequency;
          this.twoRowCard[2].value = res.data.data.TotalViewCount;
          this.twoRowCard[3].value = res.data.data.TotalConsultCount;
          this.twoRowCard[4].value = res.data.data.TotalEditCount;
          this.updateFrequency = Math.round(res.data.data.UpdateFrequency);
          this.initKnowledgeSearchRanking();
          this.initKnowledgeEditRanking();
          this.initKnowledgeSourceDistributionChart();
          this.initKnowledgeTypeDistributionChart();
          // this.initTopAssociatedTagsChart()
          this.initTopAssociatedTagsWordCloud()
        }
      })
      .catch((error) => {
        console.error("获取总览数据失败:", error);
        this.$message.error('请求失败，请稍后再试。');
      });
    },
    initKnowledgeSearchRanking() {
        const chartDom = document.getElementById('knowledgeSearchRanking');
        if (!chartDom) {
            console.error("DOM element 'knowledgeSearchRanking' not found.");
            return;
        }

        this.searchRankingChart = echarts.init(chartDom);
        // ECharts 配置
        let option = {
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: [] // 将在数据请求后填充
            },
            series: [
                {
                    name: this.$t('statistic.knowledgeRetrievalCount'),
                    type: 'bar',
                    data: [], // 将在数据请求后填充
                    itemStyle: {
                        color: '#458FFF',
                        borderRadius: [0, 7, 7, 0]
                    },
                    barWidth: "15px"
                }
            ]
        };

        // 使用 overviewDataList 中的 KnowledgeSearchRanking 填充图表数据
        const rankingData = this.overviewDataList.KnowledgeSearchRanking || [];
        option.yAxis.data = rankingData.map(item => item.knowledgeName); // 知识名称作为 y 轴数据
        option.series[0].data = rankingData.map(item => item.searchCount); // 搜索次数作为系列数据

        // 更新图表
        this.searchRankingChart.setOption(option);
    },
    initKnowledgeEditRanking() {
        const chartDom = document.getElementById('knowledgeEditRanking');
        if (!chartDom) {
            console.error("DOM element 'knowledgeEditRanking' not found.");
            return;
        }

        this.editRankingChart = echarts.init(chartDom);

        // ECharts 配置
        let option = {
            title: {
                text: ''
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01]
            },
            yAxis: {
                type: 'category',
                data: [] // 将在数据请求后填充
            },
            series: [
                {
                    name: this.$t('statistic.knowledgeEditCount'),
                    type: 'bar',
                    data: [], // 将在数据请求后填充
                    itemStyle: {
                        color: '#458FFF',
                        borderRadius: [0, 7, 7, 0]
                    },
                    barWidth: "15px"
                }
            ]
        };

        // 使用 overviewDataList 中的 KnowledgeSearchRanking 填充图表数据
        const rankingData = this.overviewDataList.KnowledgeEditRanking || [];
        option.yAxis.data = rankingData.map(item => item.knowledgeName); // 知识名称作为 y 轴数据
        option.series[0].data = rankingData.map(item => item.editCount); // 搜索次数作为系列数据

        // 更新图表
        this.editRankingChart.setOption(option);
    },
    initKnowledgeSourceDistributionChart() {
        const chartDom = document.getElementById('knowledgeSourceDistribution');
        if (!chartDom) {
            console.error("DOM element 'knowledgeSourceDistribution' not found.");
            return;
        }

        this.sourceDistributionChart = echarts.init(chartDom);

        // 来源映射
        const sourceMapping = {
            1: this.$t('statistic.knowledgeSource.localUpload'),
            2: this.$t('statistic.knowledgeSource.qyWechatSync'),
            3: this.$t('statistic.knowledgeSource.dingpanSync'),
            4: this.$t('statistic.knowledgeSource.systemAdded'),
            5: this.$t('statistic.knowledgeSource.yuqueSync'),
            6: this.$t('statistic.knowledgeSource.feishuSync'),
            7: this.$t('statistic.knowledgeSource.unknownSource')
        };

        let option = {
          tooltip: {
              trigger: 'item'
          },
          legend: {
              top: '5%',
              left: 'center'
          },
          series: [
              {
                  name: '知识来源',
                  type: 'pie',
                  radius: ['30%', '60%'], // 调整饼图大小
                  avoidLabelOverlap: false,
                  itemStyle: {
                      borderRadius: 10,
                      borderColor: '#fff',
                      borderWidth: 2
                  },
                  label: {
                      show: true,
                      formatter: '{b}: {c} ({d}%)' // 确保显示名称、值和百分比
                  },
                  emphasis: {
                      label: {
                          show: true,
                          fontSize: 16,
                          fontWeight: 'bold'
                      }
                  },
                  labelLine: {
                      show: true
                  },
                  data: [] // 将在数据请求后填充
              }
          ]
      };

      // 使用 overviewDataList 中的 KnowledgeSourceDistribution 填充图表数据
      const sourceData = this.overviewDataList.KnowledgeSourceDistribution || [];
      option.series[0].data = sourceData.map(item => ({
          value: item.count,
          name: sourceMapping[item.source] || `未知来源 (${item.source})` // 使用映射名称
      }));

      // 更新图表
      this.sourceDistributionChart.setOption(option);
    },
    initKnowledgeTypeDistributionChart() {
        const chartDom = document.getElementById('knowledgeTypeDistribution');
        if (!chartDom) {
            console.error("DOM element 'knowledgeTypeDistribution' not found.");
            return;
        }

        this.typeDistributionChart = echarts.init(chartDom);

        // ECharts 配置
        let option = {
            title: {
                text: '',
                left: 'center'
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}: {c} ({d}%)' // 显示文件类型、数量和百分比
            },
            legend: {
                type: 'scroll', // 当类型较多时，可以滚动
                orient: 'vertical',
                left: 'left',
                top: 'middle'
            },
            series: [
                {
                    name: '文件类型',
                    type: 'pie',
                    radius: ['30%', '60%'], // 调整饼图大小
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 8,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: true,
                        formatter: '{b}: {c} ({d}%)' // 显示名称、值和百分比
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: true
                    },
                    data: [] // 将在数据请求后填充
                }
            ]
        };

        // 使用 overviewDataList 中的 KnowledgeTypeDistribution 填充图表数据
        const typeData = this.overviewDataList.KnowledgeTypeDistribution || [];
        option.series[0].data = typeData.map(item => ({
            value: item.count,
            name: item.type
        }));

        // 更新图表
        this.typeDistributionChart.setOption(option);
    },
    // initTopAssociatedTagsChart() {
    //     const chartDom = document.getElementById('topAssociatedTags');
    //     if (!chartDom) {
    //         console.error("DOM element 'topAssociatedTags' not found.");
    //         return;
    //     }

    //     this.tagsChart = echarts.init(chartDom);

    //     // ECharts 配置
    //     let option = {
    //         title: {
    //             text: '',
    //             left: 'center'
    //         },
    //         tooltip: {
    //             trigger: 'axis',
    //             axisPointer: {
    //                 type: 'shadow' // 使用阴影指示器
    //             }
    //         },
    //         xAxis: {
    //             type: 'category',
    //             data: [], // 将在数据请求后填充
    //             axisLabel: {
    //                 formatter: function (value) {
    //                     if (value.length > 5) {
    //                         return value.slice(0, 5) + '...'; // 截断标签名
    //                     }
    //                     return value;
    //                 }
    //             }
    //         },
    //         yAxis: {
    //             type: 'value',
    //             name: '关联次数'
    //         },
    //         grid: {
    //             left: '3%',
    //             right: '4%',
    //             bottom: '3%',
    //             containLabel: true
    //         },
    //         series: [
    //             {
    //                 name: '关联次数',
    //                 type: 'bar',
    //                 data: [], // 将在数据请求后填充
    //                 itemStyle: {
    //                     color: '#458FFF', // 可自定义颜色
    //                     borderRadius: [7, 7, 0, 0]
    //                 },
    //                 barWidth: "20px"
    //             }
    //         ]
    //     };

    //     // 使用 overviewDataList 中的 TopAssociatedTags 填充图表数据
    //     const tagsData = this.overviewDataList.TopAssociatedTags || [];
    //     option.xAxis.data = tagsData.map(item => item.tagName); // 标签名作为 X 轴数据
    //     option.series[0].data = tagsData.map(item => item.associatedCount); // 关联次数作为系列数据

    //     // 更新图表
    //     this.tagsChart.setOption(option);
    // },
    initTopAssociatedTagsWordCloud() {
        const chartDom = document.getElementById('topAssociatedTagsWordCloud');
        if (!chartDom) {
            console.error("DOM element 'topAssociatedTagsWordCloud' not found.");
            return;
        }

        this.tagsChart = echarts.init(chartDom);

        // ECharts 词云配置
        const option = {
            tooltip: {
                show: true,
                formatter: (params) => `${params.name}: ${params.value}`
            },
            series: [{
                type: 'wordCloud',
                shape: 'circle', // 可以选择 'circle', 'cardioid', 'diamond', 'triangle-forward', 等形状
                left: 'center',
                top: 'center',
                width: '70%',
                height: '70%',
                sizeRange: [12, 36], // 字体大小范围
                rotationRange: [-90, 90], // 随机旋转角度
                rotationStep: 45,
                gridSize: 8,
                textStyle: {
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    color: function () {
                        const colors = ['#366eff', '#ff7f50', '#87cefa', '#da70d6', '#32cd32'];
                        return colors[Math.floor(Math.random() * colors.length)];
                    }
                },
                emphasis: {
                    focus: 'self',
                    textStyle: {
                        textShadowBlur: 10,
                        textShadowColor: '#333'
                    }
                },
                data: [] // 将在数据请求后填充
            }]
        };

        // 使用 `overviewDataList` 中的 `TopAssociatedTags` 填充数据
        const tagsData = this.overviewDataList.TopAssociatedTags || [];
        option.series[0].data = tagsData.map(item => ({
            name: item.tagName,
            value: item.associatedCount
        }));

        // 设置词云图选项
        this.tagsChart.setOption(option);
    },
    getCompanyAudit() {
      this.$http.get(`/tag-api/analytics/companyAudit?mainId=${this.params.mainId}`).then(res => {
        console.log('===', res);
        if (res.data.success) {
          this.companyAuditTime = res.data.data.update_time;
          // 如果获取到数据后，需要执行的操作
          // if (this.oneRowCard[0].value && this.companyAuditTime){
          //   this.updateFrequency = Math.round((this.oneRowCard[0].value / this.updateNumber()) * 100) / 100;
          // } else {
          //   this.updateFrequency = 0;
          // }
        } else {
          // 获取不到数据时，设置为默认的时间
          this.companyAuditTime = new Date('1979-01-01T00:00:00Z'); // 默认时间，使用1979年1月1日
        }
      }).catch(error => {
        console.error('Error fetching company audit:', error);
        // 错误处理：如果请求失败，也设置默认数据
        this.companyAuditTime = new Date('1979-01-01T00:00:00Z'); // 默认时间
      });
    },
    // initEcharts(){
    //   this.initKnowledgeNumber();
    //   this.initNumberOfConsultations();
    //   this.initKnowledgeType();
    //   this.initLabelAssociation();
    //   this.getConfig()
    // },
    // initKnowledgeNumber(){
    //   let url = requestUrl.statistical.knowledgeOverview;
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:1
    //   }).then(res => {
    //     console.log('initKnowledgeNumber',res)
    //     if (res.data){
    //       this.oneRowCard[0].value = res.data.totalNum;
    //       this.oneRowCard[0].newAddNum = res.data.todayKnowledgeTotal;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:2
    //   }).then(res => {
    //     if (res.data){
    //       this.oneRowCard[1].value = res.data.totalNum;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:3
    //   }).then(res => {
    //     if (res.data){
    //       this.oneRowCard[2].value = res.data.totalNum;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:4
    //   }).then(res => {
    //     if (res.data){
    //       this.oneRowCard[3].value = res.data.totalNum;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:5
    //   }).then(res => {
    //     console.log('totalNum',res)
    //     if (res.data){
    //       this.oneRowCard[4].value = res.data.totalNum;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:6
    //   }).then(res => {
    //     if (res.data){
    //       this.twoRowCard[0].value = res.data.volume;
    //       this.twoRowCard[0].newAddNum = res.data.todayVolume;
    //     }
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     userId:this.params.userId,
    //     indicator:8
    //   }).then(res => {
    //     if (res.data){
    //       this.twoRowCard[3].value = res.data.totalNum;
    //     }
    //   })
    // },
    //知识分类
    // initKnowledgeClassification(){
    //   var chartDom = document.getElementById('knowledgeClassification');
    //   this.classificationMyChart = echarts.init(chartDom);
    //   var option;
    //   option = {
    //     tooltip: {
    //       trigger: 'item', // 触发类型，可以是 'axis'（坐标轴触发）或 'item'（数据项触发）
    //       axisPointer: { // 坐标轴指示器配置
    //         type: 'shadow' // 坐标轴指示器类型，柱状图会有阴影效果
    //       }
    //     },
    //     xAxis: {
    //       type: 'category',
    //       data: [],
    //       axisLabel: {
    //         formatter: function (value) {
    //           // 根据需要格式化value，例如使用换行或省略
    //           if (value.length > 5) {
    //             // 假设只显示前10个字符，并在末尾添加省略号
    //             return value.slice(0, 5) + '...';
    //           }
    //           return value;
    //         }
    //       }
    //     },
    //     yAxis: {
    //       type: 'value'
    //     },
    //     series: [
    //       {
    //         data: [],
    //         type: 'bar',
    //         itemStyle:{
    //           color:'#458FFF',
    //           borderRadius: [7, 7, 0, 0]
    //         },
    //         barWidth:"15px"
    //       }
    //     ]
    //   };
    //   let url = requestUrl.statistical.knowledgeOverview;
    //   let list = this.cardData.list.filter(item => item.name && item.catalogueId)
    //   let catalogueIds = list.map(res => {
    //     return res.catalogueId
    //   })
    //   this.$http.post(url,{
    //     mainId:this.params.mainId,
    //     corpId:this.params.corpId,
    //     userId:this.params.userId,
    //     indicator:9,
    //     catalogueIds:catalogueIds
    //   }).then(res => {
    //     if (res.data){
    //       option.xAxis.data = list.map(res => {
    //         return res.name
    //       })
    //       let objData =  res.data;
    //       let data = []
    //       list.forEach(item => {
    //         for (const id in objData){
    //           if (item.catalogueId == id){
    //             data.push(objData[id])
    //           }
    //         }
    //       })
    //       option.series[0].data = data;
    //       this.knowledgeAnalysis.knowledgeClassification = option.series[0].data;
    //       option && this.classificationMyChart.setOption(option);
    //     }
    //   })

    // },
    initNumberOfConsultations() {
      var chartDom = document.getElementById('numberOfConsultations');
      this.consultationsMyChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          x:'center',
          y:'bottom',
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 5,
            itemStyle: {
              borderRadius: 10
            },
            data: []
          }
        ]
      };
      let url = requestUrl.statistical.knowledgeOverview;
      this.$http.post(url,{
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:10
      }).then(res => {
        if (res.data){
          for (let i=0;i<res.data.length;i++){
            let item = res.data[i];
            if (item.source == 1){
              item.sourceName = '本地上传'
            } else if (item.source == 2){
              item.sourceName = '企微微盘同步'
            } else if (item.source == 3){
              item.sourceName = '钉盘同步'
            } else if (item.source == 4){
              item.sourceName = '系统内添加'
            } else if (item.source == 5){
              item.sourceName = '语雀同步'
            } else if (item.source == 6){
              item.sourceName = '飞书同步'
            } else if (item.source == 7){
              item.sourceName = '立邦素材库同步'
            }
          }
          let data = res.data.map(res => {
            return {
              value:res.count,
              name:res.sourceName
            }
          })
          option.series[0].data = data;
          this.knowledgeAnalysis.numberOfConsultations = data;
          option && this.consultationsMyChart.setOption(option);
        }
      })

    },
    initKnowledgeType(){
      var chartDom = document.getElementById('knowledgeType');
      this.typeMyChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          x:'center',
          y:'bottom',
          type: 'scroll', // 使用滚动布局
          orient: 'horizontal', // 水平布局
          left: 'center', // 居中显示
          bottom: 10, // 距离底部 10px
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            padAngle: 2,
            itemStyle: {
              borderRadius: 10
            },
            data: []
          }
        ]
      };
      let url = requestUrl.statistical.knowledgeOverview;
      this.$http.post(url,{
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:11
      }).then(res => {
        if (res.data){
          let data = res.data.filter(res => res.name)
          option.series[0].data = data;
          this.knowledgeAnalysis.knowledgeType = data;
          option && this.typeMyChart.setOption(option);
        }
      })
    },
    initLabelAssociation(){
      var chartDom = document.getElementById('labelAssociation');
      this.associationMyChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: 'item', // 触发类型，可以是 'axis'（坐标轴触发）或 'item'（数据项触发）
          axisPointer: { // 坐标轴指示器配置
            type: 'shadow' // 坐标轴指示器类型，柱状图会有阴影效果
          }
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            formatter: function (value) {
              // 根据需要格式化value，例如使用换行或省略
              if (value.length > 5) {
                // 假设只显示前10个字符，并在末尾添加省略号
                return value.slice(0, 5) + '...';
              }
              return value;
            }
          }
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle:{
              color:'#09DDD5',
              borderRadius: [7, 7, 0, 0]
            },
            barWidth:"15px"
          }
        ]
      };
      let url = requestUrl.statistical.knowledgeOverview;
      this.$http.post(url,{
        mainId:this.params.mainId,
        corpId:this.params.corpId,
        userId:this.params.userId,
        indicator:12
      }).then(res => {
        if (res.data){
          console.log('data',res.data)
          option.xAxis.data = res.data.map(res => {
            return res.name
          })
          option.series[0].data = res.data.map(res => {
            return res.associatedCount
          })
          this.knowledgeAnalysis.labelAssociation = option.series[0].data;
          option && this.associationMyChart.setOption(option);
        }
      })
    },
    resizeFun(){
      this.classificationMyChart.resize();
      this.consultationsMyChart.resize();
      this.typeMyChart.resize();
      this.associationMyChart.resize();
    },
    handlerUnit(value){
      return value/1024/1024/1024>=1 ? (value/1024/1024/1024).toFixed(1) + 'GB' : (value/1024/1024).toFixed(1) + 'MB'
    },
    setKnowledgeCard(){
      this.knowledgeCardDialog = true;
    },
    saveSuccess(){
      this.knowledgeCardDialog = false;
      this.initKnowledgeClassification();
    },
    elDropdownvisibleChange(value) {
      this.parentDirectorySelect = value;
    },
    loadNode(node, resolve) {
      let corpId = sessionStorage.getItem('_corpId');
      let  mainId = sessionStorage.getItem('_mainId');
      let url = '';
      let isAskLightning = GetQueryString('isAskLightning') == 1;
      if(isAskLightning){
        url = requestUrl.knowledgeBase.clientSelectKnowledgeBaseStructureTree;
      } else {
        url = requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      }
      // let url =requestUrl.knowledgeBase.knowledgeBaseTreeLazy;
      url += "?corpId=" + corpId + '&mainId=' + mainId;
      url += '&parentId=' + (node.data.id ? node.data.id : -1)
      url += '&keyWord='
      let axiosDatas = [];
      if (node.level === 0) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data;
            axiosDatas.forEach(element => {
              element.leaf = false;
            });
            return resolve(axiosDatas);
          }
        })
      }
      if (node.level >= 1) {
        this.$http.get(url).then(res => {
          if (res.data.code == 0 && res.data.data){
            axiosDatas = res.data.data
            axiosDatas.forEach(element => {
              element.leaf = false;
              if (!element.haveChildren){
                element.leaf = true
              }
            });
            node.data.children = axiosDatas;
            return resolve(axiosDatas);
          }
        })
      }
    },
    nodeClick(node,item){
      this.$set(node, "checked", true);
      item.catalogueId = node.id;
      item.catalogueName = node.name;
      let messageDrop = this.$refs.messageDrop;
      if (messageDrop) {
        for (let i=0;i<messageDrop.length;i++){
          messageDrop[i].visible = false;
        }
      }
    },
    addStatistics(){
      this.cardData.list.push( {
        name:"",
        catalogueId:"",
        catalogueName:""
      })
    },
    removeStatistics(index){
      this.cardData.list.splice(index,1);
    },
    getConfig(){
      let url = requestUrl.statistical.configuration;
      let mainId = sessionStorage.getItem('_mainId');
      let corpId = sessionStorage.getItem('_corpId');
      let userId = sessionStorage.getItem('_uid');
      url += '?mainId=' + mainId + '&corpId=' + corpId + '&userId=' + userId + '&type=all'
      this.$http.get(url).then(res => {
        console.log('res',res)
        if (res.data && res.data.length > 0){
          this.cardData = res.data[0];
          this.cardDataCopy = JSON.stringify(res.data[0]);
          this.initKnowledgeClassification();
        }
      })
    },
    cancelConfig(){
      this.knowledgeCardDialog = false;
      this.cardData = JSON.parse(this.cardDataCopy)
    },
    saveConfig(){
      let url = requestUrl.statistical.configuration;
      let mainId = sessionStorage.getItem('_mainId');
      let corpId = sessionStorage.getItem('_corpId');
      let userId = sessionStorage.getItem('_uid');
      if (!this.cardData.name){
        this.$message({
          message:"请填写统计卡片名称",
          type:"warning"
        })
        return
      }
      let list = this.cardData.list.filter(res => res.name && res.catalogueId);
      let indexName = this.cardData.list.findIndex(res => !res.name);
      let indexId = this.cardData.list.findIndex(res => !res.catalogueId);
      if (indexName !== -1){
        this.$message({
          message:"请输入统计指标名称",
          type:"warning"
        })
        return;
      }
      if (indexId !== -1){
        this.$message({
          message:"请选择知识目录",
          type:"warning"
        })
        return;
      }
      let obj = {
        name:this.cardData.name,
        list:list,
        mainId,
        userId,
        corpId,
        type:"all"
      }
      if (this.cardData._id){
        url += '/' + this.cardData._id;
        this.$http.put(url,{
          name:this.cardData.name,
          list:list
        }).then(res => {
          if (res.data){
            this.$message({
              message:"保存成功",
              type:"success"
            })
            this.saveSuccess()
          }
        })
      } else {
        this.$http.post(url,obj).then(res => {
          if (res.data){
            this.$message({
              message:"保存成功",
              type:"success"
            })
            this.saveSuccess()
          }
        })
      }
    },
    updateNumber(){
      const startTime = new Date(this.companyAuditTime);
      const endTime = new Date(getDateRange().todayDateEnd);

      // 计算时间间隔（单位为毫秒）
      const timeDiff = endTime.getTime() - startTime.getTime();

      // 将时间间隔转换为天数
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
      return daysDiff;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.params = {
        mainId:sessionStorage.getItem('_mainId'),
        corpId:sessionStorage.getItem('_corpId'),
        userId:sessionStorage.getItem('_uid'),
        // indicator: 1
      }
      this.getOverviewData()
      // this.initEcharts();
      this.getCompanyAudit();
    })
    // window.addEventListener("resize", this.resizeFun);
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize',this.resizeFun)
  // }
};
</script>

<style scoped lang="less">
#knowledgeOverview{
  width: 100%;
  overflow-x: hidden;
  .header{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    .knowledgeTab{
      flex: none;
      padding: 0 14px;
      height: 30px;
      border-radius: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .activeKnowledgeTab{
      background: #366AFF;
      color: #ffffff;
    }
  }
  .KnowledgeAnalysisContainer{
    //height: calc(100% - 50px);
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;
    .cardList{
      margin-bottom: 16px;
      .oneRow,.twoRow{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cardItem{
          flex: 1;
          margin-right: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 120px;
          padding: 0 20px;
          background: #FFFFFF;
          box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
          border-radius: 5px;
          .cardLeft{
            display: flex;
            flex-direction: column;
            .cardNum{
              margin-bottom: 8px;
              display: flex;
              align-items: center;
              .number{
                color: #010101;
                font-size: 20px;
                margin-right: 4px;
              }
              .newAdd{
                display: flex;
                align-items: center;
                font-size: 12px;
                color: #366aff;
                .newAddBtn{
                  flex: none;
                  //width: 50px;
                  padding: 0 6px;
                  height: 16px;
                  background: #366AFF;
                  border-radius: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 12px;
                  margin-left: 4px;
                  color: #ffffff;
                }
              }
            }
            .cardName{
              color: #000000;
            }
          }
          .cardIcon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            flex: none;
            i{
              font-size: 20px;
            }
          }
        }
        .cardItem:last-child{
          margin-right: 0!important;
        }
      }
      .oneRow{
        .cardItem{
          margin-bottom: 16px;
        }
      }
      .twoRow{
        .list{
          flex: 1;
          height:0px;
          padding: 0 20px;
        }
      }
    }
    .analysisCard{
      height: 450px;
      background: #FFFFFF;
      box-shadow: 0px 0px 18px 0px rgba(29,55,129,0.07);
      border-radius: 5px;
      margin-bottom: 16px;
      padding: 20px 0;
      overflow-y: hidden;
      .title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        color: #000000;
        margin-bottom: 14px;
        .setBtn{
          flex: none;
          height: 26px;
          padding: 0 10px;
          background: #366AFF;
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #ffffff;
          cursor: pointer;
          i{
            padding-right: 4px;
            font-size: 14px;
          }
        }
      }
      .analysisCardLine{
        height: calc(100% - 30px);
      }
      .empty-image{
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .empty-text{
          font-size: 16px;
          color: #616161;
          margin-top: 30px;
        }
        /deep/img{
          width: 251px;
          height: 182px;
        }
      }
    }
    .pieChart{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .analysisCard{
        width: calc(50% - 10px);
      }
    }
  }
}
/deep/.popup-floating-layer{
  #popup-assembly{
    width: 800px;
  }
}
.knowledgeCardContainer{
  .card-name{
    padding-bottom: 14px;
    .title{
      padding-bottom: 10px;
    }
  }
  .statistics{
    display: flex;
    align-items: center;
    padding-bottom: 14px;
    position: relative;
    .statistics-left{
      flex: none;
      width: 260px;
      margin-right: 20px;
      .top{
        padding-bottom: 10px;
      }
    }
    .statistics-center{
      flex: 1;
      margin-right: 30px;
      .top{
        padding-bottom: 10px;
      }
    }
    .delete-icon{
      flex: none;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #366aff;
      position: absolute;
      right: 0px;
      top: 40px;
      i{
        cursor: pointer;
      }
    }
  }
  .add-statistics{
    margin-top: 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .add-btn{
      flex: none;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      background-color: #366aff;
      margin-right: 14px;
      border-radius: 5px;
      i{
        font-size: 14px;
      }
    }
  }
}
#el-dropdown-dir{
  width: 100%;
  .el-dropdown-link-dir{
    display: flex;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    .el-dropdown-placeholder{
      flex: auto;
      display: flex;
      align-items: center;
      height: 38px;
      padding: 0 18px;
      color: #C0C4CC;
    }
    .el-dropdown-dir-content {
      flex: auto;
      height: 38px;
      padding: 0 18px;
      max-width: calc(100% - 40px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      .el-tag {
        margin: 2px 5px 0 0;
      }
    }
    .el-icon{
      flex: none;
      width: 30px;
      text-align: center;
      display: flex;
      align-items: center;
    }
  }
}
/deep/.el-dropdown-menu__item:not(.is-disabled):hover{
  background-color: #FFFFFF;
}
/deep/.dir-dropdown-item{
  width: 500px!important;
  padding: 0!important;
  .dir-tree{
    max-height: 300px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    ///deep/.el-tree-node__expand-icon{
    //  display: none;
    //}
    .el-tree-node__content{
      height: 32px!important;
    }
  }
  .custom-tree-node {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;

    .custom-tree-node-left{
      display: flex;
      align-items: center;
      flex: none;
      width: calc(100% - 20px);
      .custom-tree-node-label{
        display: flex;
        align-items: center;
        width: 100%;
        flex: none;
        .folder-name{
          flex: none;
          max-width: calc(100% - 10px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        img{
          margin-right: 6px;
        }
      }
    }
    i {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
    .el-icon-caret-right{
      padding: 0 6px;
      color: #c0c4cc;
      display: flex;
      align-items: center;
      font-size: 12px;
    }
    .el-icon-caret-right{
      &.down{
        transform: rotate(90deg);
      }
      &.right{
        transform: rotate(0);
      }
    }
  }
  .el-tree{
    max-height: 300px;
  }
}
</style>